<template>
  <div>
    <skeleton v-if="isLoggedIn && !mapsLoaded" />
    <home-cards v-else-if="isLoggedIn && maps.length > 0" />
    <add-map-card v-else-if="isLoggedIn && maps.length == 0" />
    <landing v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import homeCards from "../components/Home/HomeCards.vue";
import addMapCard from "../components/Home/AddMapCard.vue";
import landing from "../components/Home/Landing.vue";
import skeleton from "../components/Home/Skeleton";

export default {
  name: "Home",
  components: {
    homeCards,
    addMapCard,
    landing,
    skeleton
  },
  data() {
    return {
      showAddMarker: false
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", "maps", "mapsLoaded"])
  },
  mounted() {
    this.$store.dispatch("clearCurrentMap");
  }
};
</script>
