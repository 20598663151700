<template>
  <v-container pa-0 container--fluid>
    <v-layout class="masthead">
      <v-parallax
        :src="`${baseUrl}img/bg-masthead.jpg`"
        class="masthead-parallax"
      >
        <v-layout class="masthead-content" wrap>
          <v-flex px-5 pt-5 xs12 md6 class="text-center">
            <h2 class="headline">
              Welcome to maps4us, where you can build your own live maps. Using
              maps4us software you can experience map transformation through
              time. Maps can include events, family, businesses with icons,
              images, annotations and custom pins.
            </h2>
            <p />
            <v-btn rounded outlined color="white" to="/login">
              Start Now for Free!
            </v-btn>
          </v-flex>
          <v-flex xs12 md6 class="text-center">
            <video class="img-fluid" autoplay loop muted playsinline>
              <source src="../../assets/map-demo.mp4" type="video/mp4" />
            </video>
          </v-flex>
        </v-layout>
      </v-parallax>
    </v-layout>

    <v-layout class="features text-center my-3" row wrap>
      <v-flex xs12 class="text-center">
        <h2 class="display-1">Create your first map today!</h2>
        <p class="caption">
          Build your own maps, share them with your friends and colleagues.
        </p>
        <v-divider />
      </v-flex>
      <br /><br /><br /><br /><br />
      <v-flex xs12 md4>
        <v-carousel
          hide-delimiters
          :show-arrows="false"
          :cycle="true"
          interval="2000"
        >
          <v-carousel-item>
            <img
              :src="`${baseUrl}img/demo-screen-1.jpg`"
              style="height:100%;width:auto;"
            />
          </v-carousel-item>
          <v-carousel-item>
            <img
              :src="`${baseUrl}img/demo-screen-2.jpg`"
              style="height:100%;width:auto;"
            />
          </v-carousel-item>
          <v-carousel-item>
            <img
              :src="`${baseUrl}img/demo-screen-3.jpg`"
              style="height:100%;width:auto;"
            />
          </v-carousel-item>
          <v-carousel-item>
            <img
              :src="`${baseUrl}img/demo-screen-4.jpg`"
              style="height:100%;width:auto;"
            />
          </v-carousel-item>
          <v-carousel-item>
            <img
              :src="`${baseUrl}img/demo-screen-5.jpg`"
              style="height:100%;width:auto;"
            />
          </v-carousel-item>
          <v-carousel-item>
            <img
              :src="`${baseUrl}img/demo-screen-6.jpg`"
              style="height:100%;width:auto;"
            />
          </v-carousel-item>
        </v-carousel>
      </v-flex>
      <v-flex xs12 md8>
        <v-container container-fluid>
          <v-layout row wrap>
            <v-flex xs12 md6>
              <div class="feature-item">
                <i class="fa fa-user-o logo_icon" />
                <h3 class="headline">Your account</h3>
                <p class="caption">All your maps in one place.</p>
              </div>
            </v-flex>
            <v-flex xs12 md6>
              <div class="feature-item">
                <i class="fa fa-map-o logo_icon" />
                <h3 class="headline">Create maps</h3>
                <p class="caption">
                  You can create maps with our tools or import your own data.
                </p>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 md6>
              <div class="feature-item">
                <i class="fa fa-magic logo_icon" />
                <h3 class="headline">Publish maps</h3>
                <p class="caption">
                  Publish your maps so they can be shared anywhere on the web.
                </p>
              </div>
            </v-flex>
            <v-flex xs12 md6>
              <div class="feature-item">
                <i class="fa fa-mobile logo_icon" />
                <h3 class="headline">Fully Responsive</h3>
                <p class="caption">Works on both desktop and mobile.</p>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-container container--fluid class="cta">
        <v-parallax :src="`${baseUrl}img/bg-cta.jpg`">
          <v-container>
            <h2 class="display-3 white--text">
              Live Maps. <br />
              Build. <br />
              Publish. <br />
              Impress.
            </h2>
            <v-btn rounded outlined color="white" to="/login">
              Let's Get Started!</v-btn
            >
          </v-container>
        </v-parallax>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Landing",
  data() {
    return {
      baseUrl: process.env.BASE_URL
    };
  }
};
</script>

<style>
.masthead-content {
  padding-top: 150px;
  padding-bottom: 100px;
  color: white;
}

.masthead .v-btn__content {
  color: white !important;
}

.masthead-parallax {
  height: auto !important;
}

video {
  max-width: 100%;
}

section.features .feature-item {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

section.features .feature-item h3 {
  font-size: 30px;
}

.v-carousel {
  -webkit-box-shadow: 0px 0px 0px red;
  box-shadow: 0px 0px 0px red;
}
.features .feature-item i {
  font-size: 80px;
  display: block;
  margin-bottom: 15px;
  background: -webkit-linear-gradient(to left, #e63f5b, #fa8a5a, #f5725e);
  background: linear-gradient(to left, #e63f5b, #fa8a5a, #f5725e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cta {
  position: relative;
  padding: 50px 0 !important;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.cta .v-btn__content {
  color: white;
}

.footer {
  padding: 20px 0;
  text-align: center;
  color: #424168 !important;
}

.footer a {
  text-decoration: none;
  color: #424168 !important;
}

.footer a:hover,
.footer a:focus,
.footer a:active,
.footer a.active {
  text-decoration: none;
}
</style>
