<template>
  <v-container container--fluid grid-list-md pa-5>
    <v-layout>
      <v-flex xs12 sm6 offset-sm3>
        <v-card elevation="6">
          <v-card-text>
            <v-skeleton-loader
              type="list-item-avatar, divider, list-item-three-line"
            ></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: "Skeleton"
};
</script>
